body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.vui-secondary-btn:not(:disabled) {
  background: #00629d;
  color: #fff;
  box-shadow: 0 2px 4px #00000026;
}

.vui-btn {
  padding: 10px 20px;
  border-radius: 2px;
  border: none;
  position: relative;
  display: inline-flex;
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: .05em;
  text-transform: capitalize;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #ffc600;
  box-shadow: 0 2px 4px #00000026;
  color: #000;
}

.vui-comp-render {
  padding: 10px;
    border-bottom: 1px solid #737373;
    position: relative;
    overflow-x: auto;
}
.mb-3{
  margin-bottom: 1rem!important;
}
.vui-phDefault {
  font-family: Roboto;
  font-weight: 400;
  font-size: 28px;
  color: #000;
  line-height: 33px;
  text-align: center;
}

.open{
  color: #006BA6;
}
.open{
  color: #006BA6;
}

.open:hover{
  cursor: pointer;
  text-decoration: underline;
}